
import { defineComponent } from 'vue'
import Slideshow from './header/Slideshow.vue'
import { subpages } from '@/data/navigation/subpages'
import { langs } from '@/data/navigation/langs'

interface Subpage {
  text: string
  link?: string
  links?: {
    en: string
    jp: string
    kr: string
  }
  scrollTo?: string
  corename: string
}

export default defineComponent({
  data(): { subpages: Subpage[]; langs: typeof langs } {
    return {
      subpages,
      langs,
    }
  },
  components: {
    Slideshow,
  },
  computed: {
    currentLanguage(): string {
      return this.$store.getters.getAppLanguage
    },
    getAppStoreLink(): string {
      const appStoreSubpage = this.subpages.find(
        (subpage) => subpage.corename === 'App Store'
      )
      if (appStoreSubpage) {
        return this.getLinkForCurrentLanguage(appStoreSubpage)
      }
      return '#'
    },
  },
  methods: {
    isLanguageKey(key: string): key is 'en' | 'jp' | 'kr' {
      return ['en', 'jp', 'kr'].includes(key)
    },
    getLinkForCurrentLanguage(subpage: Subpage) {
      if (subpage.links && this.isLanguageKey(this.currentLanguage)) {
        return subpage.links[this.currentLanguage]
      }
      return '#'
    },
  },
})
