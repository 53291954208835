export const socialMedia = [
  //   {
  //     Icon: 'facebook-f',
  //     Link: 'https://www.instagram.com/workout.history/',
  //   },
  //   {
  //     Icon: 'github',
  //     Link: 'https://github.com/YIMSUHWAN/WorkoutHistory',
  //   },
  //   {
  //     Icon: 'youtube',
  //     Link: 'https://www.instagram.com/workout.history/',
  //   },
  //   {
  //     Icon: 'twitter',
  //     Link: 'https://www.instagram.com/workout.history/',
  //   },
  //   {
  //     Icon: 'instagram',
  //     Link: 'https://www.instagram.com/workout.history/',
  //   },
  // {
  //   Icon: 'reddit-alien',
  //   Link: 'https://github.com/Genesis-Respositiories',
  // },
  // {
  //   Icon: 'discord',
  //   Link: 'https://github.com/Genesis-Respositiories',
  // },
  //   {
  //     Icon: 'heart',
  //     Collection: 'fas',
  //     Support: true,
  //     Link: 'https://github.com/Genesis-Respositiories',
  //   },
]
