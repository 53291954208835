import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02cfd408"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slideshow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (slide, index) => {
      return _createVNode(_component_Hero, {
        key: index,
        index: index,
        activeSlide: _ctx.activeSlide,
        isVisible: index === _ctx.activeSlide
      }, null, 8, ["index", "activeSlide", "isVisible"])
    }), 64))
  ]))
}