
import { defineComponent } from 'vue'
import Hero from './Hero.vue'

export default defineComponent({
  components: {
    Hero,
  },
  data() {
    return {
      activeSlide: Math.floor(Math.random() * 3),
    }
  },
  methods: {
    changeSlide() {
      if (this.activeSlide < 3) {
        this.activeSlide++
      } else {
        this.activeSlide = 0
      }
    },
  },
  mounted() {
    window.setInterval(() => {
      this.changeSlide()
    }, 3000)
  },
})
