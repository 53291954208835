
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<string[]>,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.options.length > 0 ? this.options[0] : '',
      filterOptions: this.options as string[],
      open: false,
      key: 0,
    }
  },
  watch: {
    '$route.params.lang'(newLang) {
      if (newLang && this.options.includes(newLang)) {
        this.updateSelectedLocale(newLang)
      }
    },
    '$i18n.locale'(newLocale) {
      this.updateSelectedLocale(newLocale)
    },
  },
  computed: {
    currentLanguage(): string {
      return this.$store.getters.getAppLanguage
    },
  },
  methods: {
    changeLanguage(lang: string) {
      this.$i18n.locale = lang
      this.$store.commit('setAppLanguage', lang)
      const filter = this.options.filter(
        (option) => option != this.$i18n.locale
      )
      if (filter) this.filterOptions = filter
    },
    updateSelectedLocale(locale: string) {
      this.selected =
        this.options[this.options.findIndex((opt) => opt == locale)]
      this.filterOptions = this.options.filter(
        (option) => option !== this.selected
      )
      this.$emit('input', this.selected)
      this.$store.commit('setAppLanguage', locale)
    },
  },
  mounted() {
    const savedLang = this.$store.getters.getAppLanguage

    if (savedLang && this.options.includes(savedLang)) {
      this.$i18n.locale = savedLang
      this.updateSelectedLocale(savedLang)
    } else {
      this.updateSelectedLocale(this.$i18n.locale)
    }
  },
})
