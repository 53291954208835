import store from '@/store' // Vuex 스토어를 가져옵니다.

const setLang = (lang: string) => {
  if (['en', 'kr', 'jp'].includes(lang)) {
    store.commit('setAppLanguage', lang)
    document.documentElement.setAttribute('lang', lang)
  } else {
    const selectedLang = store.getters.getAppLanguage
    store.commit('setAppLanguage', selectedLang)
    document.documentElement.setAttribute('lang', selectedLang)
  }
}

export default setLang
