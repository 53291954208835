
import { defineComponent, watch } from 'vue'
import { socialMedia } from '@/data/navigation/footer'
import Brands from './Brands.vue'

export default defineComponent({
  data() {
    return {
      socialMedia,
      year: new Date().getFullYear(),
    }
  },
  components: {
    Brands,
  },
  computed: {
    currentLanguage(): string {
      return this.$store.getters.getAppLanguage
    },
    // privacyPolicyLink(): string {
    //   return `/${this.currentLanguage}/privacy-policy`
    // },
    // termsOfUseLink(): string {
    //   return `/${this.currentLanguage}/terms-of-use`
    // },
    privacyPolicyLink(): string {
      return `/privacy-policy`
    },
    termsOfUseLink(): string {
      return `/terms-of-use`
    },
  },
  created() {
    // watcher 설정
    watch(
      () => this.currentLanguage,
      (newValue, oldValue) => {
        // console.log(`Language changed from ${oldValue} to ${newValue}`)
      }
    )
  },
})
