
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    header: String,
    content: String,
    linkcont: String,
    link: String,
    genesis: Boolean,
    textAlign: {
      type: String,
      default: 'right',
    },
  },
})
