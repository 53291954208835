import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '../views/Home.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/components/Info/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import('@/components/Info/TermsOfUse.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
  // ... 추가적인 경로들
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        window.scrollTo({
          top: savedPosition.top,
          behavior: 'smooth',
        })
        resolve(savedPosition)
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        resolve({ top: 0, left: 0 })
      }
    })
  },
})

router.afterEach((to) => {
  if (
    !to.path.includes('privacy-policy') &&
    !to.path.includes('terms-of-use')
  ) {
    window.scrollTo(0, (router as any).savedScrollPosition || 0)
  }
})

export default router
