
import { defineComponent } from 'vue'
import { User } from '@/types/user'
import DProfileData from './DProfileData.vue'

export default defineComponent({
  data() {
    return {
      user: this.$store.getters.getUser as User,
    }
  },
  components: {
    DProfileData,
  },
})
