import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d55bd53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "desktop" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_DProfile = _resolveComponent("DProfile")!
  const _component_Locale = _resolveComponent("Locale")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Logo, { onClick: _ctx.scrollToTop }, null, 8, ["onClick"]),
    _createElementVNode("section", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subpagesWithScrollTo, (subpage) => {
        return (_openBlock(), _createElementBlock("a", {
          key: 'scroll-' + subpage.text,
          onClick: ($event: any) => (_ctx.smoothScroll(subpage))
        }, _toDisplayString(subpage.corename), 9, _hoisted_2))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subpagesWithoutScrollTo, (subpage) => {
        return (_openBlock(), _createElementBlock("a", {
          key: 'link-' + subpage.text,
          href: _ctx.getLinkForCurrentLanguage(subpage),
          target: "_blank",
          rel: "noopener noreferrer"
        }, _toDisplayString(subpage.corename), 9, _hoisted_3))
      }), 128))
    ]),
    _createVNode(_component_DProfile),
    _createVNode(_component_Locale, {
      options: _ctx.langs,
      selectedLanguage: _ctx.currentLanguage
    }, null, 8, ["options", "selectedLanguage"])
  ]))
}