import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5714100d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "aboutPro" }
const _hoisted_2 = { class: "video-wrap gif-container" }
const _hoisted_3 = { class: "html-embed w-embed" }
const _hoisted_4 = { class: "video-mask" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock("video", {
            key: _ctx.demo,
            class: "video-mask",
            height: "100%",
            width: "100%",
            autoplay: "",
            muted: "",
            loop: "",
            playsinline: ""
          }, [
            _createElementVNode("source", {
              src: _ctx.demo,
              type: "video/mp4"
            }, null, 8, _hoisted_5)
          ]))
        ])
      ])
    ]),
    _createVNode(_component_Text, {
      header: _ctx.formattedHeader,
      content: _ctx.formattedDesc,
      linkcont: _ctx.$t('home.aboutPro.button'),
      link: "info",
      textAlign: "left"
    }, null, 8, ["header", "content", "linkcont"])
  ]))
}