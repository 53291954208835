import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d11f12d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile" }
const _hoisted_2 = {
  key: 0,
  class: "site-menu"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Locale = _resolveComponent("Locale")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Logo, {
      class: "logo",
      onClick: _ctx.scrollToTop
    }, null, 8, ["onClick"]),
    _createVNode(_component_Locale, { options: _ctx.langs }, null, 8, ["options"]),
    (_ctx.showSideMenu == true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ul", null, [
            _createElementVNode("div", {
              class: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSideMenu(false)))
            }),
            _createVNode(_component_Logo, { class: "logo" }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subpagesWithoutScrollTo, (subpage) => {
              return (_openBlock(), _createElementBlock("a", {
                key: 'link-' + subpage.text,
                href: _ctx.getLinkForCurrentLanguage(subpage),
                target: "_blank",
                rel: "noopener noreferrer"
              }, _toDisplayString(subpage.corename), 9, _hoisted_3))
            }), 128)),
            _createVNode(_component_Locale, { options: _ctx.langs }, null, 8, ["options"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}