
import { defineComponent } from 'vue'
import Text from './common/Text.vue'

export default defineComponent({
  components: {
    Text,
  },
  data() {
    return {
      demo: '',
    }
  },
  computed: {
    currentLanguage(): string {
      return this.$store.getters.getAppLanguage
    },
    formattedHeader() {
      return this.$t('home.formulas.header').replace(/\n/g, '<br>')
    },
    formattedDesc() {
      return this.$t('home.formulas.desc').replace(/\n/g, '<br>')
    },
  },
  watch: {
    currentLanguage(newLang) {
      this.updateMediaSources(newLang)
    },
  },
  methods: {
    updateMediaSources(lang: string) {
      this.demo = require(`@/assets/demo/demo3-${lang}.mp4`)
    },
  },
  created() {
    this.updateMediaSources(this.currentLanguage)
  },
})
