const resource = {
  "main": {
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI 맞춤으로 피트니스 혁신을 경험하세요."])}
  },
  "home": {
    "about": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI와\n운동 시작하기"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI가 당신의 피트니스 목표와 레벨을 고려해 특정 머슬그룹을 위한 최적의 운동을 제공합니다."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out more"])}
    },
    "aboutPro": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과 검증된\n템플릿으로 운동 시작하기"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 레벨과 목표에 맞춘, 효과가 검증된 예제 워크아웃으로 이상적인 피트니스를 경험하세요."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out more"])}
    },
    "formulas": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운동 기록\n및 분석"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각각의 운동을 기록하고, 그래프와 캘린더를 통해 진행 상황을 확인하세요."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out more"])}
    },
    "statistics": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운동 공유\n및 평가"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 유저와 당신의 운동을 공유하고, 서로의 운동을 평가하며 피드백을 주고받으세요."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 알아보기"])}
    }
  },
  "navigation": {
    "navbar": {
      "subpages": {
        "formulas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulas"])},
        "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
        "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
        "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])}
      }
    },
    "footer": {
      "copyrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All photos and logos used are copyright-free and made available under the MIT or CC license. It is allowed to use, copy, modify or distribute the original or modified content on this website without restriction, provided that the authorship is acknowledged and the source information is posted."])}
    }
  },
  "sciences": {
    "groups": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "natural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural"])},
      "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
      "humanities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humanities"])},
      "technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical"])},
      "artistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artistic"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])}
    },
    "sciences": {
      "philology_iberian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iberian Philology"])},
      "philology_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Philology"])},
      "philology_france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French and Benelux Philology"])},
      "philology_italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian Philology"])},
      "philology_german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German Philology"])},
      "philology_poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish, Ukrainian and Lithuanian Philology"])},
      "philology_baltic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baltic Philology"])},
      "philology_nordic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordic Philology"])},
      "philology_russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian Philology"])},
      "philology_carpathian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpathian Philology"])},
      "philology_romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian nad Bulgarian Philology"])},
      "philology_balkan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West-Balkan Philology"])},
      "philology_greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek Philology"])},
      "philology_turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish Philology"])},
      "philology_middleeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle East, Arabic and Persian Philology"])},
      "philology_indian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indian Philology"])},
      "philology_chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Philology"])},
      "philology_korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean Philology"])},
      "philology_japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese Philology"])},
      "anthropology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anthropology"])},
      "philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philosophy"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "historyofart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of Art"])},
      "culturalstudies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cultural Studies"])},
      "politology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politology"])},
      "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law"])},
      "psychology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychology"])},
      "theology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theology"])},
      "defensestudies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defense Studies"])},
      "sociology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociology"])},
      "internationalstudies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Studies"])},
      "economics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economics"])},
      "finances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances"])},
      "accountancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountancy"])},
      "econometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Econometrics"])},
      "governance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governance"])},
      "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
      "astronomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Astronomy"])},
      "bioinformatics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bioinformatics"])},
      "biochemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biochemistry"])},
      "biophysics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biophysics"])},
      "biotechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biotechnology"])},
      "biology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biology"])},
      "chemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chemistry"])},
      "physics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physics"])},
      "geography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geography"])},
      "geology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geology"])},
      "informatics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatics"])},
      "math": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mathematics"])},
      "ecology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecology"])},
      "architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architecture"])},
      "robotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotics"])},
      "building_eng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building Engeenering"])},
      "energetics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energetics"])},
      "electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronics"])},
      "quantum_eng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantum Engeenering"])},
      "cosmonautics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosmonautics"])},
      "mechanics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechanics"])},
      "mechatronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechatronics"])},
      "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])}
    },
    "branches": {
      "physics": {
        "kinematics": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinematics"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We study the motion of bodies, including their acceleration, path and speed"])},
          "subjects": {
            "linear-motion": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear Motion"])},
              "formulas": {
                "velocity-basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average speed"])},
                "velocity-deriv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantaneous speed"])},
                "acceleration-basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average acceleration"])},
                "acceleration-deriv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantaneous acceleration"])},
                "motion-eq-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equations of motion cz. 1"])},
                "motion-eq-b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equations of motion cz. 2"])},
                "motion-eq-c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equations of motion cz. 3"])},
                "motion-eq-d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equations of motion cz. 4"])}
              }
            }
          }
        },
        "dynamics": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamika"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajmiemy się opisem sił działających na różne ciała, a także ich skutkami"])}
        },
        "energy": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praca moc i energia to wielkości, które pozwolą nam spojrzeć na mechanikę z innej strony, a także będą wstępem do kolejnych działów fizyki."])}
        }
      },
      "electronics": {
        "current": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prąd elektryczny"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaczynamy od podstaw, czyli czym jest prąd elektryczny"])}
        }
      }
    },
    "units": {
      "velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość"])},
      "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droga"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas"])},
      "acceleration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyspieszenie"])}
    }
  },
  "pages": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona główna"])},
    "formulas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablice"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artykuły"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podręczniki"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statystyki"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wesprzyj"])}
  },
  "formulas": {
    "index": {
      "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wzory i tablice naukowe"])}
    },
    "pick": {
      "science": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz jedną z kategorii"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz dziedzinę nauki, która cię interesuje"])}
    }
  },
  "errors": {
    "404": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz system nie znalazł takiej strony :c"])},
      "duck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więc łap chociaż na pocieszenie kaczkę"])},
      "yeah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, to ten sławny bląd 404"])},
      "song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bit, bit i pusta kartka, drukarka wydaje szmery Nie ma mnie, bo to nocnego marka nocna warta Świeci się tarcza zegarka - 4:04 Nic nie psuje atmosfery, dźwięki smarta czy wiertarka"])}
    }
  },
  "info": {
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ale czy aby na pewno?"])},
    "sections": {
      "begginings": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początki"])}
      },
      "activity": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działalność"])}
      },
      "goalsandplans": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel i plany"])}
      },
      "software": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprogramowanie"])},
        "langscategories": {
          "front-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-End"])},
          "back-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-End"])},
          "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilne"])},
          "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazy danych"])},
          "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])}
        }
      },
      "sponsors": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorzy"])}
      },
      "authors": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorzy"])}
      },
      "thanks": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podziękowania"])}
      },
      "donate": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wesprzyj"])}
      },
      "join": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz"])}
      },
      "contact": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
      }
    }
  }
}
export default resource