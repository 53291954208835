import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f7b41a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "custom-select",
    key: _ctx.key,
    tabindex: _ctx.tabindex,
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["selected", { open: _ctx.open }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, [
      _createElementVNode("img", {
        src: require('@/assets/icons/navbar/flags/' + _ctx.selected + '.png')
      }, null, 8, _hoisted_2),
      _createTextVNode(" " + _toDisplayString(_ctx.selected.toUpperCase()), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["items", { selectHide: !_ctx.open }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterOptions, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: i,
          onClick: ($event: any) => {
          ;(_ctx.selected = option),
            (_ctx.open = false),
            _ctx.$emit('input', option),
            _ctx.changeLanguage(option)
        }
        }, [
          _createElementVNode("img", {
            src: require('@/assets/icons/navbar/flags/' + option + '.png')
          }, null, 8, _hoisted_4),
          _createTextVNode(" " + _toDisplayString(option.toUpperCase()), 1)
        ], 8, _hoisted_3))
      }), 128))
    ], 2)
  ], 40, _hoisted_1))
}