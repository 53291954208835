
import { defineComponent } from 'vue'

import Header from '@/components/Home/Header.vue'
import About from '@/components/Home/About.vue'
import AboutPro from '@/components/Home/AboutPro.vue'
import Formulas from '@/components/Home/Formulas.vue'
import Statistics from '@/components/Home/Statistics.vue'

import setMeta from '@/scripts/root/setMeta'

export default defineComponent({
  components: {
    Header,
    About,
    AboutPro,
    Formulas,
    Statistics,
  },
  methods: {
    setMeta,
  },
  computed: {
    isMainRoute(): boolean {
      // 현재 URL 경로에 'PrivacyPolicy' 또는 'termsOfUse'가 포함되어 있지 않으면 true 반환
      return !(
        this.$route.path.includes('privacy-policy') ||
        this.$route.path.includes('terms-of-use')
      )
    },
  },
  created() {
    setMeta(document, {
      // 앱의 전체 타이틀(탭바 타이틀)
      // 탭바의 이미지를 변경하고 싶은 경우, favicon을 수정.
      title: 'AI Trainer',
    })
  },
})
