
import { defineComponent } from 'vue'
import { User } from '@/types/user'
import Desktop from './Desktop.vue'
import Mobile from './Mobile.vue'

export default defineComponent({
  components: {
    Desktop,
    Mobile,
  },
  data() {
    return {
      setNavColor: false,
      user: {} as User,
    }
  },
  methods: {
    scrollHandler() {
      if (window.scrollY > 20) {
        this.setNavColor = true
      } else {
        this.setNavColor = false
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', () => this.scrollHandler())
  },
})
