import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'
import store from '@/store/index'

function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    '@/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

// 브라우저의 언어 설정을 가져옵니다. 
// 예: "en-US" 또는 "ko-KR"와 같은 형식이 될 수 있으므로, 첫 부분만 가져옵니다.
const browserLanguage = (navigator.language).split('-')[0];

// 사용 가능한 언어 목록을 가져옵니다.
const availableLanguages = Object.keys(loadLocaleMessages());

// 브라우저의 언어가 사용 가능한 언어 목록에 포함되어 있는지 확인합니다.
const initialLanguage = availableLanguages.includes(browserLanguage) ? browserLanguage : store.getters.getAppLanguage;

const i18n = createI18n({
  legacy: false,
  locale: initialLanguage,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  globalInjection: true,
  messages: loadLocaleMessages(),
})

export default i18n
