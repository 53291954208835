import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51af51f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imgcont" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", { src: _ctx.imageSrc }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Text, {
        header: _ctx.$t('home.statistics.header'),
        content: _ctx.$t('home.statistics.desc'),
        linkcont: _ctx.$t('home.statistics.button'),
        link: "statistics",
        textAlign: "center"
      }, null, 8, ["header", "content", "linkcont"])
    ])
  ]))
}