import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "workouthistory-app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_Navbar),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer),
    _createVNode(_component_notifications, {
      position: "bottom left",
      width: "400px"
    })
  ]))
}