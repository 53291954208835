
import { defineComponent, computed } from 'vue'
import Text from './common/Text.vue'

export default defineComponent({
  components: {
    Text,
  },
  computed: {
    // Assuming you have a method or getter to get the current language
    currentLanguage(): string {
      return this.$store.getters.getAppLanguage
    },
    // Computed property to get the image source based on current language
    imageSrc(): string {
      return require(`@/assets/pages/home/home2-${this.currentLanguage}.png`)
    },
  },
})
