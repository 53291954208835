
import { defineComponent } from 'vue'
import Navbar from '@/components/Shared/Navbar/Navbar.vue'
import Footer from '@/components/Shared/Footer/Footer.vue'
import Locale from './desktop/Locale.vue'
import setLang from '@/scripts/root/setLang'

export default defineComponent({
  methods: {
    setLang,
    mapToSupportedLanguage(lang: string): string {
      const mapping: { [key: string]: string } = {
        ko: 'kr',
        'ko-KR': 'kr',
        'ko-kr': 'kr',
        en: 'en',
        'en-US': 'en',
        'en-us': 'en',
        'en-UK': 'en',
        'en-uk': 'en',
        'en-AU': 'en',
        'en-au': 'en',
        ja: 'jp',
        'ja-JP': 'jp',
        'ja-jp': 'jp',
      }
      return mapping[lang] || lang
    },
    async fetchLanguageFromHeader() {
      // 먼저 브라우저 설정에서 언어를 가져옵니다.
      let browserLang = navigator.language.slice(0, 2)

      // 언어 맵핑 함수를 사용하여 지원되는 언어 코드로 변환
      browserLang = this.mapToSupportedLanguage(browserLang)

      const supportedLanguages = ['en', 'kr', 'jp']

      // 브라우저 언어를 지원하는 언어 목록에 포함되어 있으면 그 언어로 설정합니다.
      if (supportedLanguages.includes(browserLang)) {
        this.setLang(browserLang)
        return
      }

      try {
        // 브라우저 언어를 지원하지 않는 경우 현재 페이지에 요청을 보냅니다.
        const response = await fetch(window.location.href, {
          method: 'HEAD', // 헤더만 가져오기 위해 HEAD 요청 사용
        })

        // `X-Custom-Language` 헤더를 추출합니다.
        const customLang = response.headers.get('X-Custom-Language')

        if (customLang) {
          this.setLang(customLang)
        } else {
          // 브라우저 언어도 지원하지 않고 헤더 값도 없거나 읽을 수 없는 경우, 기본 언어로 설정
          this.setLang('en')
        }
      } catch (error) {
        console.error('Error fetching language header:', error)
        this.setLang('en') // 오류가 발생하면 기본 언어로 설정
      }
    },
  },
  created() {
    this.fetchLanguageFromHeader()
  },
  components: {
    Navbar,
    Footer,
  },
})
