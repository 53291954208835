import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["hero", { active: _ctx.index == _ctx.activeSlide }]),
    style: _normalizeStyle({
      backgroundImage:
        'url(' +
        require('@/assets/pages/home/hero/' + _ctx.index + '-min.jpg') +
        ')',
    })
  }, null, 6))
}