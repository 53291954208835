import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab5511c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profiledata" }
const _hoisted_2 = { class: "username" }
const _hoisted_3 = { class: "login" }
const _hoisted_4 = { class: "icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, _toDisplayString(_ctx.user.Name + ' ' + _ctx.user.Surname), 1),
    _createElementVNode("li", _hoisted_3, "@" + _toDisplayString(_ctx.user.Login), 1),
    _createElementVNode("section", _hoisted_4, [
      _createVNode(_component_router_link, {
        class: "link_user",
        to: '/users/' + _ctx.user.Login
      }, null, 8, ["to"]),
      _createVNode(_component_router_link, {
        class: "link_messages",
        to: '/messages'
      }),
      _createVNode(_component_router_link, {
        class: "link_bell",
        to: '/notifications'
      }),
      _createVNode(_component_router_link, {
        class: "link_settings",
        to: "/settings"
      }),
      _createVNode(_component_router_link, {
        class: "link_logout",
        to: "/logout"
      })
    ])
  ]))
}