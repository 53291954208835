
import { defineComponent } from 'vue'
import { subpages } from '@/data/navigation/subpages'
import { langs } from '@/data/navigation/langs'
// import MProfile from './mobile/MProfile.vue'
// import NavItem from './mobile/NavItem.vue'
// import Hamburger from './mobile/Hamburger.vue'
import Logo from './Logo.vue'
import Locale from './mobile/Locale.vue'

interface Subpage {
  text: string
  link?: string
  links?: {
    en: string
    jp: string
    kr: string
  }
  scrollTo?: string
  corename: string
}

export default defineComponent({
  data(): { subpages: Subpage[]; langs: typeof langs; showSideMenu: boolean } {
    return {
      subpages,
      langs,
      showSideMenu: false,
    }
  },
  components: {
    // Hamburger,
    // MProfile,
    // NavItem,
    Logo,
    Locale,
  },
  computed: {
    currentLanguage(): string {
      return this.$store.getters.getAppLanguage
    },
    subpagesWithScrollTo(): Subpage[] {
      return this.subpages.filter((subpage: Subpage) => subpage.scrollTo)
    },
    subpagesWithoutScrollTo(): Subpage[] {
      return this.subpages.filter((subpage: Subpage) => !subpage.scrollTo)
    },
  },
  methods: {
    setSideMenu(val: boolean) {
      this.showSideMenu = val
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    smoothScroll(subpage: Subpage) {
      if (subpage.scrollTo) {
        const targetElement = document.getElementById(subpage.scrollTo)
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
    isLanguageKey(key: string): key is 'en' | 'jp' | 'kr' {
      return ['en', 'jp', 'kr'].includes(key)
    },
    getLinkForCurrentLanguage(subpage: Subpage) {
      if (subpage.links && this.isLanguageKey(this.currentLanguage)) {
        return subpage.links[this.currentLanguage]
      }
      return '#'
    },
  },
})
