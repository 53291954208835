import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Desktop = _resolveComponent("Desktop")!
  const _component_Mobile = _resolveComponent("Mobile")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_Desktop, {
      class: _normalizeClass({ changed: _ctx.setNavColor })
    }, null, 8, ["class"]),
    _createVNode(_component_Mobile, {
      class: _normalizeClass({ changed: _ctx.setNavColor })
    }, null, 8, ["class"])
  ]))
}