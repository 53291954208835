import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7152f9d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _mergeProps({ style: { 'text-align': _ctx.textAlign } as any }, { innerHTML: _ctx.header }), null, 16, _hoisted_2),
    _createElementVNode("div", _mergeProps({ style: { 'text-align': _ctx.textAlign } as any }, { innerHTML: _ctx.content }), null, 16, _hoisted_3)
  ]))
}