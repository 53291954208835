import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0e6584a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "username" }
const _hoisted_3 = { class: "profile_hitbox" }
const _hoisted_4 = { class: "profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DProfileData = _resolveComponent("DProfileData")!

  return (_ctx.user && Object.keys(_ctx.user).length > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("article", null, [
          _createElementVNode("div", {
            class: _normalizeClass(["avatarcont", { premium: _ctx.user.isSponsor || _ctx.user.isGenesisMember }])
          }, [
            _createElementVNode("section", {
              class: "avatar",
              style: _normalizeStyle({
            backgroundImage: 'url(/user/avatar/' + _ctx.user.avatarFileID + '.png)',
          })
            }, null, 4)
          ], 2),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.user.Name), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("section", _hoisted_4, [
            _createVNode(_component_DProfileData)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}