import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_About = _resolveComponent("About")!
  const _component_AboutPro = _resolveComponent("AboutPro")!
  const _component_Formulas = _resolveComponent("Formulas")!
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isMainRoute)
      ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade",
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        (_ctx.isMainRoute)
          ? (_openBlock(), _createBlock(_component_About, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "fade",
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        (_ctx.isMainRoute)
          ? (_openBlock(), _createBlock(_component_AboutPro, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "fade",
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        (_ctx.isMainRoute)
          ? (_openBlock(), _createBlock(_component_Formulas, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "fade",
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        (_ctx.isMainRoute)
          ? (_openBlock(), _createBlock(_component_Statistics, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "fade",
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        (!_ctx.isMainRoute)
          ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}